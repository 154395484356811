import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from './Components/Header'
import Homescreen from './Components/Homescreen'
import Signin from './Components/Signin'
import Signup from './Components/Signup'



const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Homescreen />}/>
        <Route path='/sign' element={<Signin />}/>
        <Route path='/signup' element={<Signup />}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App
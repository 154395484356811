import React from 'react'
import Footer from './Footer'
import Header from './Header'
import LandingPage from './LandingPage'
// import Hero from './Popular'
import Popular from './Popular'
import Spotlight from './Spotlight'
import Started from './Started'
import Land from "./Hero"
import Hero from './Hero'
import Define from './Define'
import Happen from './Happen'

const Homescreen = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Define />
      <Happen />
      <LandingPage />
      {/* <Spotlight /> */}
      <Popular />
      <Started />
      <Footer />
    </div>
  )
}

export default Homescreen
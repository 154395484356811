import React from 'react'
import styled from 'styled-components'
import images from "./Assets/space.svg"
import images2 from "./Assets/spaner.svg"
import images3 from "./Assets/api.svg"
import images4 from "./Assets/workspace.svg"
import images5 from "./Assets/governance.svg"

const Define = () => {
  return (
    <Container>
      <Wrapper>
        <Top>
          <Left>
            <Bold>What is Postman?</Bold>
            <P>Postman is an API platform for building and using APIs. Postman simplifies each step of 
             the API lifecycle and streamlines collaboration so you can create better APIs—faster.</P>
          </Left>
          <Right>
            <Images src={images} />
          </Right>
        </Top>
        <Down>
          <Card>
            <Api src={images2} />
            <Tools>API Tools</Tools>
            <Sm>A comprehensive set of tools that help accelerate the API Lifecycle - from design,
            testing, documentation, and mocking to discovery.</Sm>
          </Card>
          <Card>
            <Api src={images3} />
            <Tools>API Tools</Tools>
            <Sm>A comprehensive set of tools that help accelerate the API Lifecycle - from design,
            testing, documentation, and mocking to discovery.</Sm>
          </Card>
          <Card>
            <Api src={images4} />
            <Tools>API Tools</Tools>
            <Sm>A comprehensive set of tools that help accelerate the API Lifecycle - from design,
            testing, documentation, and mocking to discovery.</Sm>
          </Card>
          <Card>
            <Api src={images5} />
            <Tools>API Tools</Tools>
            <Sm>A comprehensive set of tools that help accelerate the API Lifecycle - from design,
            testing, documentation, and mocking to discovery.</Sm>
          </Card>
        </Down>
        <Button>Learn more</Button>
      </Wrapper>
    </Container>
  )
}

export default Define
const Button = styled.div`
  cursor: pointer;
  justify-content: center;
    color: rgb(33, 33, 33);
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.16);
    height: 30px;
    padding: 8px 10px;
    border-radius: 4px;
    margin-top: 60px;
    align-items: center;
    display: flex;
`
const Sm = styled.div`
  font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: rgb(107, 107, 107);
    flex-wrap: wrap;
`
const Tools = styled.h3`
  font-size: 17px;
    line-height: 1.28;
    color: rgb(33, 33, 33);
    letter-spacing: -0.12px;
    font-weight: 600;
`
const Api = styled.img`
  height: 35px;
`
const Card = styled.div`
  width: 22%;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: flex-start;
  margin-top: 25px;
  padding: 12px;
  border-radius: 3px;
  /* margin-bottom: 5px; */
  flex-wrap: wrap;
  border: 1px solid rgb(209, 208, 208);
  @media screen and (max-width: 1358px){
    width: 47%;
  }
  @media screen and (max-width: 1024px){
    width: 46%;
  }
  @media screen and (max-width: 768px){
    width: 44%;
  }
   @media screen and (max-width: 565px){
    width: 42%;
  }
  @media screen and (max-width: 425px){
    width: 40%;
  }
  @media screen and (max-width: 320px){
    width: 39%;
  }
`
const Down = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* background-color: red; */
  flex-wrap: wrap;
  @media screen and (max-width: 762px){
    /* flex-wrap: nowrap; */
  }
`
const Images = styled.img`
  height: 230px;
  margin-top: -100px;
  @media screen and (max-width: 1024px){
    height: 190px;
  }
`
const Right = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 1024px){
    width: 40%;
  }
   @media screen and (max-width: 768px){
    display: none;
  }
`
const P = styled.div`
  color: rgb(107, 107, 107);
  font-size: 15px;
    line-height: 1.6;
    width: 100%;
`
const Bold = styled.h2`
  font-weight: 600;
    word-break: normal;
    font-size: 25px;
    line-height: 1.2;
    color: rgb(33, 33, 33);
    letter-spacing: -0.72px;
    margin-bottom: 16px;
`
const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1024px){
    width: 60%;
  }
   @media screen and (max-width: 768px){
    width: 100%;
    /* background-color: red; */
  }
`
const Top = styled.div`
  width: 100%;
  display: flex;
`
const Wrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
    /* background-color: red; */
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #F9F9F9;
  margin-top: 60px;
  padding-bottom: 70px;
`
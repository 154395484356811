import React from "react";
import styled from "styled-components";
import future from "./Assets/future.png";

const FutureComponent = () => {
  return (
    <div>
      <Container>
        <Wrapper>
          <Left>
          <Heading>The future is API-first</Heading>
          <Content>
            At Postman, we believe the future will be built with APIs. The
            API-first World graphic novel tells the story of how and why the API-first world is coming
            to be.
          </Content>
          <Images src={future} />
          <Button>Read the Graphic Novel</Button>
        </Left>
        <Right>
          <Image src={future} />
        </Right>
        </Wrapper>
      </Container>
    </div>
  );
};

export default FutureComponent;
const Images = styled.img`
  width: 270px;
  margin-bottom: 20px;
  margin-left: 10px;
  display: none;
  @media screen and (max-width: 992px){
    display: flex;
  }
`
const Wrapper = styled.div`
  width: 90%;
  display: flex;
  /* background-color: red; */
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f8f8f8;
  padding-top: 50px;
  padding-bottom: 50px;
`;
const Left = styled.div`
  /* margin-right: 50px; */
  /* margin-left: 50px; */
  margin-bottom: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 992px){
    width: 100%;
  }
`;
const Heading = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  color: rgb(33, 33, 33);
  letter-spacing: -0.72px;
  margin: 0;
`;
const Content = styled.p`
  font-size: 15px;
  color: #6d6d6d;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;
const Button = styled.button`
  width: 150px;
  height: 30px;
  color: #6d6d6d;
  border: 1px solid #6d6d6d;
  background-color: #f8f8f8;
  border: 1px solid #a7a7a7;
  border-radius: 3px;
  font-size: 12px;
  transition: all 350ms;
  :hover {
    border: 1px solid #5e5e5e;
  }
`;
const Right = styled.div`
  object-fit: cover;
  object-position: left;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  /* background-color: blue; */
  @media screen and (max-width: 992px){
    display: none;
  }
`;
const Image = styled.img`
  /* object-fit: cover;
  object-position: left; */
  height: 260px;
  margin-top: 25px;
  @media screen and (max-width: 1116px){
    height: 230px;
  }
`;

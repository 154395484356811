import React from 'react'
import styled from 'styled-components'
import images from "./Assets/back.png"
import images2 from "./Assets/calc.svg"
import images3 from "./Assets/news.png"

const Hero = () => {
  return (
    <Container>
      <Wrapper>
        <Left>
          <Top>Build <br />APIs together</Top>
          <P>Over 20 million developers use Postman. Get <br /> started by signing up or downloading the desktop <br /> app</P>
          <InputHold>
                    <Input />
                    <Button2>Sign Up for Free</Button2>
                </InputHold>
                <Down>Download the desktop app</Down>
                <Holder>
                  <Calc src={images2} />
                </Holder>
                <NewsHolder>
                  <News src={images3} />
                </NewsHolder>
        </Left>
        <Right>
          <Images src={images}/>
        </Right>
      </Wrapper>
    </Container>
  )
}

export default Hero
const News = styled.img`
  height: 350px;
  display: none;
  @media screen and (max-width: 768px){
    display: flex;
    margin-top: 10px;
    width: 100%;
  }
  @media screen and (max-width: 530px){
    display: flex;
    margin-top: 10px;
    height: 70%;
  }
`
const Calc = styled.img`
  height: 140px;
  display: none;
  @media screen and (max-width: 768px){
    display: flex;
  }
`
const NewsHolder = styled.div`
  width: 100%;
  display: flex;
`
const Holder = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
const Down = styled.div`
  font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    color: rgb(107, 107, 107);
    margin-top: 30px;
    @media screen and (max-width: 714px){
    display: none;
  }
`
const Button2 = styled.div`
	height: 39px;
    background-color: rgb(255, 108, 55);
    color: rgb(255, 255, 255);
    font-weight: 600;
	border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 14px;
	align-items: center;
    width: 125px;
    cursor: pointer;
	justify-content: center;
	display: flex;
    @media screen and (max-width: 768px){
    width: 87%;
    margin-top: 15px;
  }
  @media screen and (max-width: 1050px){
    width: 93%;
    /* margin-top: 15px; */
  }
`
const Input = styled.input`
    width: 66%;
    height: 35px;
    display: flex;
    border: 1px solid #FF6C37;
    outline: none;
    padding-left: 10px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    @media screen and (max-width: 1048px){
        width: 85%;
    }
`
const InputHold = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 15px;
    @media screen and (max-width: 768px){
    flex-wrap: wrap;
  }
  @media screen and (max-width: 320px){
     width: 85%;
    }
`
const P = styled.div`
  font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: rgb(33, 33, 33);
    @media screen and (max-width: 320px){
     width: 85%;
    }
`
const Top = styled.h1`
  font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    color: rgb(33, 33, 33);
    @media screen and (max-width: 320px){
     width: 85%;
    }
`
const Images = styled.img`
    margin-left: -70px;
`
const Right = styled.div`
  width: 55%;
  height: 100%;
  display: flex;
@media screen and (max-width: 1024px){
    width: 45%;
  }
  @media screen and (max-width: 768px){
    display: none;
  }
`

const Left = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  margin-top: 260px;
  @media screen and (max-width: 1024px){
    width: 45%;
  }
  @media screen and (max-width: 768px){
    width: 100%;
    margin-top: 130px;
  }
  @media screen and (max-width: 320px){
    width: 100%;
    margin-top: 110px;
    align-items: center;
  }
`

const Wrapper = styled.div`
  width: 90%;
  display: flex;
  height: 100%;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`